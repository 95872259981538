// extracted by mini-css-extract-plugin
export var column = "Terms__column__kcF9H";
export var flex = "Terms__flex__ft83D";
export var flexColumn = "Terms__flexColumn__eJMPZ";
export var gap1 = "Terms__gap1__WOx9X";
export var gap2 = "Terms__gap2__z8qhc";
export var gap3 = "Terms__gap3__Tcl9u";
export var gap4 = "Terms__gap4__r4WY3";
export var gap5 = "Terms__gap5__fWfHX";
export var row = "Terms__row__Udu5X";
export var terms = "Terms__terms__fy0wC";
export var termsLayout = "Terms__termsLayout__girXU";
export var title = "Terms__title__dKH1H";