// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../../styleguide/components/Paragraph/P.res.js";
import * as TermsScss from "./Terms.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = TermsScss;

function Terms$default(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("h1", {
                              children: "Datacenters.com Terms of Use"
                            }),
                        JsxRuntime.jsx("h2", {
                              children: "Last Modified - January 12, 2021"
                            })
                      ],
                      className: css.title
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(P.make, {
                              children: "Datacenters.com (also referred to herein as \"we,\" \"us,\" and \"our\") operates this website\n          (referred to herein as the \"Site\") to provide online access and information about datacenter,\n          Internet network and cloud providers, products, services, and locations. By accessing and using the\n          Site, you agree to each of the terms and conditions set forth herein, along with the terms and\n          conditions of our Privacy Policy (collectively referred to herein as the these \"Terms of Use\").\n          Additional terms and conditions applicable to specific areas of the Site or to content or transactions\n          are also posted, and may be posted in the future, to areas of the Site and, together with these\n          Terms of Use, govern your use of those areas, content, or transactions. These Terms of Use\n          incorporate by reference all such additional terms and conditions."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Please read these Terms of Use carefully, as they contain important information about limitations\n          of liability and resolution of disputes through arbitration rather than court."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Datacenters.com reserves the right to modify these Terms of Use at any time without giving you\n          prior notice. Your use of the Site following any such modification constitutes your agreement to\n          follow and be bound by these Terms of Use as modified. The last date these Terms of Use were revised\n          is set forth above."
                            }),
                        JsxRuntime.jsx("h2", {
                              children: "The Site is Informational"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "The Site is informational, providing online access and information about datacenter, Internet,\n          network and cloud providers, products, services, and locations.  We encourage all users of the\n          Site to be responsible and cautious in their commercial dealings, confirming the accuracy of the\n          content contained on the Site for their own account."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "You agree that you are responsible for, and agree to abide by, all laws, rules and regulations\n          applicable to your use of the Site, or of any tool, service, or product offered on the Site, and\n          any transaction you enter into in connection with your use of the Site."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "You further agree that you shall abide by all laws, rules, ordinances, or regulations applicable\n          to the listing or purchasing of your products and services, including but not limited to any and all\n          laws, rules, ordinances, regulations or other requirements relating to taxes, credit cards, data and\n          privacy, permits or license requirements, zoning ordinances, safety compliance and compliance with\n          all anti-discrimination laws, as applicable.  Please be aware that, even though we are not a party\n          to any transaction and assume no liability for legal or regulatory compliance pertaining to products\n          or services listed on the Site, there may be circumstances where we are nevertheless legally\n          obligated (as we may determine in our sole discretion) to provide information relating to your\n          activity on the Site in order to comply with requests from governmental bodies in relation to\n          investigations, litigation or administrative proceedings, and we may choose to comply with such\n          obligations in our sole discretion."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Users of the Site who accept credit card, banking or other payment information agree to properly\n          handle and safeguard all such information in accordance with applicable legal and regulatory\n          requirements and best practices."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "While we do take certain measures with a goal to assist users to avoid potentially fraudulent or\n          other illegal activity of which we become aware, we assume no liability or obligation to take any\n          such measures or actions. When we provide warnings or messages to users about any such activity, we\n          do not warrant that such messages are accurate or that such messages will reach any or all users\n          they should have reached in a timely manner or at all or that such messages or measures will prevent\n          any harm or otherwise have any impact."
                            }),
                        JsxRuntime.jsx("h2", {
                              children: "Limited License to Use the Site"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "You are granted a limited, revocable, non-exclusive license to access and use the Site, and the\n          information, writings, images and/or other works that you see, hear, or otherwise experience on the\n          Site (singly or collectively, the \"Content\") solely to learn about or list products and services.\n          Any use of the Site that is not for this purpose or is otherwise not in accordance with these Terms\n          of Use is expressly prohibited."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "No right, title, or interest in any Content is transferred to you, whether downloading such Content\n          or otherwise. Datacenters.com reserves complete title and full intellectual property rights in all\n          Content. Except as expressly authorized by these Terms of Use, you may not use, alter, copy, distribute,\n          transmit, or derive another work from any Content obtained from the Site, except as expressly\n          permitted by these Terms of Use."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "In additional to these Term of Use, your limited license to use the Site is further limited by any\n          and all restrictions and obligations set forth in any written agreement by and between Datacenters.com\n          and the organization for which you provide services, if any, where such services require or permit\n          your use of the Site."
                            }),
                        JsxRuntime.jsx("h2", {
                              children: "Unauthorized Uses of the Site"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Your limited license to use the Site only extends to the uses expressly described herein. Your\n          limited license to use the Site granted in these Terms of Use does not include any right of\n          collection, aggregation, copying, scraping, duplication, display, or any derivative use of the Site,\n          nor any right of use of data mining, robots, spiders, or similar data gathering and extraction tools\n          without our prior written permission; provided, however, that a limited exception from the foregoing\n          exclusion is provided to general purpose internet search engines that use tools to gather information\n          for the sole purpose of displaying hyperlinks to the Site, provided they each do so from a stable\n          IP address or range of IP addresses using an easily identifiable agent and comply with our robots.txt\n          file."
                            }),
                        JsxRuntime.jsx("h2", {
                              children: "Copyright"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "The Site and the Content are protected by U.S. and/or foreign copyright laws, and belong to Datacenters.com\n          or its partners, affiliates, or contributors.  The copyrights in the Content are owned by Datacenters.com\n          or other copyright owners who have authorized their use on the Site. You may download and reprint\n          Content for non-commercial, non-public, personal use only. If you are browsing the Site as an employee\n          or member of any business or organization, you may download and reprint content only for educational\n          or other non-commercial purposes within your business or organization, except as otherwise permitted\n          by Datacenters.com, for example in certain password-restricted areas of the Site. Except for the\n          sample images, you may not manipulate or alter in any way images or other content on the Site."
                            }),
                        JsxRuntime.jsx("h2", {
                              children: "Sample Images"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Datacenters.com has included sample images and stock photos on certain pages within the Site to\n          demonstrate the capability of the Site. You may download, reprint, and manipulate the sample images\n          for this purpose, but you may not crop or change the composition of the image, or use the image for\n          commercial purposes."
                            }),
                        JsxRuntime.jsx("h2", {
                              children: "Trademarks"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "You are prohibited from using any of the marks or logos appearing throughout the Site without\n          permission from Datacenters.com."
                            }),
                        JsxRuntime.jsx("h2", {
                              children: "Limitations on Use of Other Users' Information"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "You agree that, with respect to the personal information of other users of the Site that you obtain\n          directly or indirectly from or through the Site or through any Site-related communication, transaction\n          or software, we have granted to you a license to use such information only for: (i) Site-related\n          communications that are not unsolicited commercial messages, (ii) using services offered through the\n          Site, and (iii) inquiring about or otherwise facilitating a financial transaction between you and\n          the other user related to the purpose of the Site. Any other purpose will require express permission\n          from the user. You may not use any such information for any unlawful purpose or with any unlawful intent."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "In all cases, you must give users an opportunity to remove their information from your address book\n          or database or other records and a chance to review what information you have collected about them.\n          In addition, under no circumstances, except as defined in this provision, may you disclose personal\n          information about another user to any third-party without the consent of the other user. You agree\n          that other users may use your personal information to communicate with you in accordance with this\n          provision. Further, you agree that you will protect other users\' personal information with the same\n          degree of care that you protect your own confidential information (using at minimum a reasonable\n          standard of care), and you assume all liability for the misuse, loss, or unauthorized transfer of\n          such information."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "We do not tolerate spam or unsolicited commercial electronic communications of any kind. Therefore,\n          without limiting the foregoing, you are not licensed to add a user of the Site, even a user who has\n          entered into a transaction with you, to your mailing list (email or physical mail) without the user's\n          express consent. You may not use any tool or service on the Site to send spam or unsolicited\n          commercial electronic communications of any kind or in any other way that would violate these\n          Terms of Use."
                            }),
                        JsxRuntime.jsx("h2", {
                              children: "Links to Third-Party Websites"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Links on the Site to third-party websites or information are provided solely as a convenience to you.\n          If you use these links, you will leave the Site. Such links do not constitute or imply an endorsement,\n          sponsorship, or recommendation by Datacenters.com of the third-party, the third-party website, or\n          the information contained therein. Datacenters.com is not responsible for the availability of any\n          such websites. Datacenters.com is not responsible or liable for any such website or the content\n          thereon. If you use the links to any third-party websites, you will leave the Site, and will be\n          subject to the terms of use and privacy policy applicable to those websites."
                            }),
                        JsxRuntime.jsx("h2", {
                              children: "Downloading Files"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Datacenters.com cannot and does not guarantee or warrant that files available for downloading\n          through the Site will be free of infection by software viruses or other harmful computer code,\n          files, or programs."
                            }),
                        JsxRuntime.jsx("h2", {
                              children: "Software"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Any software available for download via the Site (singly or collectively, “Software”) is the\n          copyrighted work of Datacenters.com and/or its licensors. Use of Software is governed by the terms\n          of this Agreement and the end-user license agreement that accompanies or is included therewith.\n          Downloading, installing, and/or using any Software indicates your acceptance of the terms of the\n          applicable end-user license agreement."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "You acknowledge and agree that the Site and Software, and their structures, organizations, and\n          source codes, constitute valuable trade secrets of Datacenters.com and its suppliers.  You will not,\n          and will not permit any third party to:  (a) modify, adapt, alter, translate, or create derivative\n          works from the Site or Software; (b) merge the Software with other software other than through\n          interfaces published by Datacenters.com; (c) sublicense, distribute, sell, use for service bureau\n          use or as an application service provider, lease, rent, loan, or otherwise transfer the Software\n          to any third party; (d) reverse engineer, decompile, disassemble, or otherwise attempt to derive\n          the source code for the Site or Software (except to the extent required by applicable law); (e)\n          remove, alter, cover, or obfuscate any copyright notices or other proprietary rights notices\n          included on the Site or in the Software; or (f) otherwise use or copy the Site or Software except\n          as expressly permitted in this Agreement (or the applicable end-user license agreement)."
                            }),
                        JsxRuntime.jsx("h2", {
                              children: "Disclaimer of Warranties"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Datacenters.com makes no express or implied warranties, representations, or endorsements whatsoever\n          with respect to the Site or the Content, or any tool, service, or product offered on the Site.\n          Datacenters.com expressly disclaims all warranties of any kind (express, implied, statutory or\n          otherwise) related to the Site or the Content, or any tool, service, or product offered on the Site,\n          including, but not limited to, implied warranties of merchantability, fitness for a particular purpose,\n          title, and non-infringement.  Datacenters.com does not warrant that the functions performed by the\n          Site or any tool, service, or product offered on the Site will be uninterrupted, timely, secure, or\n          error-free, or that defects in the Site or such tools, services, or products will be corrected.\n          Datacenters.com does not warrant the accuracy or completeness of the Content, or that any errors\n          in the Content will be corrected. The Site and the Content, any all tools, services, and products\n          offered on the Site, are offered and provided on an \"as is\" and \"as available\" basis."
                            }),
                        JsxRuntime.jsx("h2", {
                              children: "Limitation of Liability"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "In no event will Datacenters.com be liable for any damages whatsoever, including, but not limited\n          to any direct, incidental, consequential, special, exemplary, or other indirect damages arising out\n          of (i) the use of or inability to use the Site or the Content, or any tool, service, or product offered\n          on the Site, (ii) any transaction conducted through or facilitated by the Site, (iii) any claim attributable\n          to errors, omissions, or other inaccuracies in the Site or the Content, or any tool, service, or product\n          offered on the Site, (iv) unauthorized access to, or alteration of, any data transmission facilitated\n          by the Site, or (v) any other matter relating to the Site or the Content, or any tool, service, or\n          product offered on the Site, even if Datacenters.com has been advised of, or is otherwise aware of,\n          the possibility of such damages. If you are dissatisfied with the Site or the Content, or any tool,\n          service, or product offered on the Site, or these Terms of Use, your sole and exclusive remedy is to\n          discontinue using the Site."
                            }),
                        JsxRuntime.jsx("h2", {
                              children: "Limitation of Liability"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "IN THE EVENT THAT YOU HAVE A DISPUTE WITH ONE OR MORE OTHER USERS OF THE SITE (INCLUDING, WITHOUT\n          LIMITATION, ANY DISPUTE BETWEEN USERS REGARDING ANY TRANSACTION OR USER-CONTRIBUTED CONTENT) OR ANY\n          THIRD-PARTY PROVIDER OR ANY THIRD-PARTY WEBSITE THAT MAY BE LINKED TO OR FROM OR OTHERWISE INTERACT\n          WITH THE SITE, YOU HEREBY AGREE TO RELEASE, REMISE AND FOREVER DISCHARGE DATACENTERS.COM,\n          AND EACH OF ITS AFFILIATES, PARTNERS, LICENSORS, EMPLOYEES, AGENTS, AND THIRD-PARTY REPRESENTATIVES,\n          FROM ANY AND ALL MANNER OF RIGHTS, CLAIMS, COMPLAINTS, DEMANDS, CAUSES OF ACTION, PROCEEDINGS, LIABILITIES,\n          OBLIGATIONS, LEGAL FEES, COSTS, AND DISBURSEMENTS OF ANY NATURE WHATSOEVER, WHETHER KNOWN OR UNKNOWN,\n          WHICH NOW OR HEREAFTER ARISE FROM, RELATE TO, OR ARE CONNECTED WITH SUCH DISPUTE AND/OR YOUR USE OF\n          THE SITE."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH SAYS: \"A\n          GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST\n          IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH, IF KNOWN BY HIM MUST HAVE MATERIALLY\n          AFFECTED HIS SETTLEMENT WITH THE DEBTOR.\""
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "You understand and agree that you are personally responsible for your behavior on the Site. You\n          agree to indemnify, defend, and hold harmless Datacenters.com, its affiliates, partners, licensors,\n          employees, agents, and third-party representatives from and against all claims, losses, expenses,\n          damages, and costs (including, but not limited to, direct, incidental, consequential, exemplary and\n          indirect damages), and reasonable attorneys\' fees, resulting from or arising out of your use, misuse,\n          or inability to use the Site or the Content, or any tool, service, or product offered on the Site, or\n          any violation by you of these Terms of Use."
                            }),
                        JsxRuntime.jsx("h2", {
                              children: "Disputes; Arbitration"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "We are committed to customer satisfaction, so if you have a problem or dispute, we will try to\n          resolve your concerns. But if we are unsuccessful, you may pursue claims only as explained in this\n          section and subject to the limitations provided in these Terms of Use. You agree to give us an\n          opportunity to resolve any disputes or claims relating in any way to the Site or the Content, or\n          any tool, service, or product offered on the Site, any representations made by us, or our Privacy\n          Policy (referred to herein, collectively, as \"Claims\") by contacting us at info@datacenters.com.\n          If we are not able to resolve your Claims in a timely fashion (and in no event outside of 90 days),\n          you may seek relief through arbitration as set forth below."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Any and all Claims will be resolved by binding arbitration, rather than in court. This includes any\n          Claims you assert against us or any of our affiliates (which are beneficiaries of this arbitration\n          agreement).  This also includes any Claims that arose before you accepted these Terms of Use,\n          regardless of whether prior versions of these Terms of Use required arbitration."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "There is no judge or jury in arbitration, and court review of an arbitration award is limited.\n          However, an arbitrator can award on an individual basis the same damages and relief as a court\n          (including statutory damages, attorneys\' fees and costs), and must follow and enforce these Terms\n          of Use as a court would."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Arbitrations will be conducted by the American Arbitration Association (AAA) under its rules,\n          including the AAA Arbitration Consumer Rules (together, the \"AAA Rules\"). Payment of all filing,\n          administration, and arbitrator fees will be governed by the AAA\'s rules. You may choose to have\n          an arbitration conducted by telephone, based on written submissions, or in person in the state\n          where you live or at another mutually agreed upon location."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "By agreeing to arbitration under the AAA Rules, the parties agree, among other things, that the\n          arbitrator, and not any federal, state, or local court or agency, shall have the exclusive power\n          to rule on any objections with respect to the existence, scope, or validity of the arbitration\n          agreement or to the arbitrability of any claim or counterclaim."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Prior to beginning an arbitration proceeding, you must send a letter describing your Claims to\n          \"Datacenters.com Legal: Arbitration Claim\" at our principal office address then-listed on the Site.\n          If we request arbitration against you, we will give you notice at the email address or street address\n          you have provided. The AAA's rules and filing instructions are available at www.adr.org."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Any and all proceedings to resolve Claims will be conducted only on an individual basis and not in\n          a class, consolidated or representative action. If for any reason a Claim proceeds in court rather\n          than in arbitration we each waive any right to a jury trial. The Federal Arbitration Act and federal\n          arbitration law apply to these Terms of Use. An arbitration decision may be confirmed by any court\n          with competent jurisdiction."
                            }),
                        JsxRuntime.jsx("h2", {
                              children: "General Terms"
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "To Contact Us for any reason, users can send an email to info@datacenters.com."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "We may change, suspend, or discontinue any aspect of the Site at any time, including the availability\n          of any Site features, database or content. We may also impose limits on certain features or services\n          or restrict your access to parts or the entire Site without notice or liability.  This version of\n          these Terms of Use became effective on the date set forth above and this version amends the version\n          effective before such date."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "These Terms of Use constitute the entire agreement between us and you with respect to the matters\n          set forth herein, and supersede any prior agreement between us and you with respect to your use of\n          the Site. Headings in these Terms of Use are for reference only and do not limit the scope or extent\n          of such section. In the event of any conflict between these Terms of Use and any other terms and\n          conditions applicable to a product, tool or service offered on our Site, the terms herein shall prevail.\n          If any portion of these Terms of Use is found to be invalid or unenforceable by any court of competent\n          jurisdiction, the other provisions of these Terms of Use shall remain in full force and effect.\n          Further, any provision of these Terms of Use held invalid or unenforceable only in part or degree\n          will remain in full force and effect to the extent not held invalid or unenforceable."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "We may assign these Terms of Use in our sole discretion. Users must obtain our prior written consent\n          to assign these Terms of Use, which may be granted or withheld by us in our sole discretion."
                            }),
                        JsxRuntime.jsx(P.make, {
                              children: "Datacenters.com is committed to an inclusive marketplace built on a foundation of trust and respect.\n          Our global platform is open to users of every race, color, religion, national origin, ancestry, sex,\n          gender identity, sexual orientation, and marital status. We expect all users to demonstrate respect\n          and tolerance in all interactions with other users-both online and off-line-and we reserve the\n          right to remove anyone from our marketplace who fails to abide by these principles."
                            })
                      ],
                      className: css.terms
                    })
              ],
              className: css.termsLayout
            });
}

var $$default = Terms$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
